import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown"

const Accordion = ({ subSection, navigatedToWildCard }) => {
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		if (navigatedToWildCard === subSection.slug) {
			setIsActive(true)
		} else if (navigatedToWildCard !== subSection.slug) {
			setIsActive(false)
		}
	}, [subSection])

	const imageRenderer = (props) => {
		return <img {...props} className="image--portrait" />
	  }

	  return (
		<>
			<div className="accordion">
				<div
					className={`accordion__container-section-name--${isActive}`}
					onClick={() => setIsActive(!isActive)}
				>
					{isActive ? <span className="accordion__toggle">&#8211;</span> : <span className="accordion__toggle">+</span>}
                        
                        <h3 className="accordion__section-name">
							{subSection.slug.replaceAll("-", " ")}
                        </h3>
				</div>

				{isActive && 
					<div className="accordion__container-section-content">
						<ReactMarkdown 
							escapeHtml={false} 
							children={subSection.markdown} 
							components={{img: imageRenderer}}
						/>
					</div>}
			</div>
		</>
	);
};

export default Accordion;
