import React from "react";


const Loading = () => {
    

    return (
        <div className="loading__container" >
            <span className="loading__spinner"></span>
        </div>
    )
}

export default Loading;