import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import categories from "../data/menuItems.json";

export const Dropdown = ({ category, handleMenuItemClick, menuOpen }) => {
  const [dropdownOpen, setDropdownOpen] = useState();

  useEffect(() => {
    if (menuOpen === false) {
      setDropdownOpen()
    }
  })

  const handleDropdownToggle = (event, category) => {
    if (category !== dropdownOpen) {
        setDropdownOpen(category);
    }
    if (category === dropdownOpen) {
        setDropdownOpen();
    }
};

  return (
    <>
      {
        category.subItems.length ?
        <>
            <button
              className="dropdown__toggle"
              onClick={(event) => handleDropdownToggle(event, category.slug)}
            >
          {dropdownOpen === category.slug ? 
              <i className="fas fa-angle-up sub-nav__icon"></i> :  <i className="fas fa-angle-down sub-nav__icon"></i>
          }
          </button>

        </>
        : 
        null
      }

        {dropdownOpen == category.slug && 
          <ul className="dropdown">
              {category.subItems.map(
                  (subItem, index) => {
                      return (
                          <>
                            <li
                                key={`dropdown-${subItem.slug}`}
                                className="dropdown__item"
                                onClick={event => handleMenuItemClick(event, `${category.slug}/${subItem.slug}`)}
                            >
                              {subItem.slug.replaceAll("-", " ")} 
                            </li>
                          </>
                  )
              })}
              </ul>
        }
    </>
  );
};

export default Dropdown;
