import React from "react";
import Header from "./header";
import ScrollTop from "./scrollTop";

const Layout = ({ children }) => {
    return (
        <>
            <Header />
            
            <main className="main">
            {children}
            <ScrollTop />
            </main>
            
        </>
    );
};

export default Layout;
