import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "../components/layout";

const Home = () => {
    // useEffect(() => {
    //     fetch(README)
    //         .then((res) => res.text())
    //         .then((md) => {
    //         this.setState({ md })
    //         })
    // }, [])

    // let navigate = useNavigate();
    
    // useEffect(() => {
        // if (!loggedIn) {
        //     console.log(loggedIn)
        //     navigate(`/login`);
        // }
    // }, [])

    return (
        <>
            <Layout>
                <Outlet />
            </Layout>
        </>
    )
};

export default Home;