import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout";

const Login = ({setCookie}) => {
    const [loginError, setLoginError] = useState(false);

  let navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        const pass = "family";
        const value = event.target.password.value;
        console.log(process.env.REACT_APP_PASSWORD)
        if (pass === value || pass === value.toLowerCase()) {
          const value = Math.floor(
            1000000000000000 + Math.random() * 9000000000000000
        );
        setCookie("family-handbook_logged-in", value, 30); // currently calculating expirationDays x 1 minute ( expirationDays * 60 * 1000)
        navigate("/");
        } else {
          setLoginError("Incorrect user name or password")
        }
      };

    return (
        <>
            <Layout>
                <h2>Login</h2>

                <form
                  className="login" 
                  onSubmit={event => handleLogin(event)}
                >
                    <label htmlFor="password">
                        <input 
                          className="login"
                          id="password" 
                          type="text" placeholder="Password"
                        />
                    </label>
                    <button 
                      type="submit"
                      className="login__submit"
                    > Submit</button>
                </form>

                <br />
                {loginError && <p className="login__error">{loginError}</p>}
            </Layout>
        </>
    )
};

export default Login;