import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import categories from "../data/menuItems.json";
import logo from "../assets/images/logo.png"
import { Dropdown } from "./menu";
import useMediaQuery from "../lib/useMediaQuery"

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    // const [dropdownOpen, setDropdownOpen] = useState();

    const navigate = useNavigate();
    const isDesktop = useMediaQuery(1000);
  
    const handleMenuToggle = () => {
        // event.preventDefault();
        setMenuOpen(!menuOpen);
    };
  
    useEffect(() => {
        const body = document.body;
        menuOpen && !isDesktop
            ? body.classList.add("no-scroll")
            : body.classList.remove("no-scroll");

     
    }, [menuOpen]);
  
  const handleMenuItemClick = (event, path) => {
    event.preventDefault();
    navigate(`${path}`)

    if (!isDesktop) {
      setMenuOpen(!menuOpen)
    }
  }


useEffect(() => {

  const setDesktopMenu = () => {
    if (isDesktop) {
      setMenuOpen(true)

      const body = document.body;
      if (isDesktop && body.classList.contains("no-scroll")) {
        body.classList.remove("no-scroll")
      }
    } else if (!isDesktop) {
      setMenuOpen(false)    
    } 
  }

  setDesktopMenu()
}, [isDesktop])

    return (
        <>

        <header className="header">

            <button
                className={`menu-button ${menuOpen}`}
                onClick={handleMenuToggle}
            >
                <span className={`line one--${menuOpen}`}></span>
                <span className={`line two--${menuOpen}`}></span>
                <span className={`line three--${menuOpen}`}></span>
            </button>

                <Link className="header__container-logo" to="/" >

                    <img 
                        className="header__logo"
                        src={logo} 
                        alt="Canucks Logo" 
                    />   
                    <h1 className="header__title">Canucks Family Handbook</h1>
                </Link>
            
        </header>
        <nav className={`nav--${menuOpen}`}>
        <ul className={`nav__list--${menuOpen}`}>
            {categories.map(category => {
              return (
                <li  
                    className="nav__item"
                >
                  {/* <Link 
                    className="nav__link" */}
                    {/* to={category.slug}> */}
                  {/* </Link> */}
                  
                  <div
                    className="nav__link"
                    onClick={event => handleMenuItemClick(event, category.slug)}
                  >
                    {category.slug.replaceAll("-", " ")}
                  </div>

                 <Dropdown category={category} handleMenuItemClick={handleMenuItemClick} menuOpen={menuOpen} />
                </li>
              )
            })}
        </ul>
      </nav>
        </>
    );
};

export default Header;
