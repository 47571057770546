import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown"
import AccordionList from "../components/accordionList"; 

const MarkdownPage = () => {
    const [currentPage, setCurrentPage] = useState()
    const [navigatedToWildCard, setNavigatedToWildCard] = useState()

    let { fileName } = useParams();

    if (fileName === undefined) {
        fileName = "welcome";
    }

    const params = useParams()
    const paramKeys = Object.keys(params)
    const wildCardKey = paramKeys[0]
    const wildCard = params[wildCardKey]


    useEffect(() => {
        const filePath = require(`../data/markdown/${fileName}/${fileName}.md`);

        fetch(filePath)
        .then(response => {
          return response.text()
        })
        .then(text => {
            setCurrentPage(text)
        })

        if (wildCard && navigatedToWildCard !== wildCard) {
            setNavigatedToWildCard(wildCard)
        } else if (wildCard && navigatedToWildCard === wildCard) {
            setNavigatedToWildCard()
        } 
        else if (fileName === undefined) {
            setNavigatedToWildCard("key-contacts")
        }
    }, [fileName])

    const imageRendererLandscape = (props) => {
		return <img {...props} className="image--landscape" />
	  }

    return (
       <>
            <section className="page">

                <ReactMarkdown 
                    children={currentPage} 
					components={{img: imageRendererLandscape}}
                />

                        <AccordionList fileName={fileName} navigatedToWildCard={navigatedToWildCard} />
                <Outlet />                
                
            </section>
       </>
    )
};

export default MarkdownPage;