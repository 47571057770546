import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet
} from "react-router-dom";
import "./styles/global.scss"
import Home from "./pages/Home"
import MarkdownPage from "./pages/MarkdownPage"
import Login from "./pages/Login"

function App() {

  const setCookie = (cookieKey, cookieValue, expirationDays) => {
      let expiryDate = "";
      if (expirationDays) {
          const date = new Date();
  
          // date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
          date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000); // currently calculation expirationDays x 1 day ( expirationDays * 24 * 60 * 60 * 1000)
          expiryDate = "expires=" + date.toUTCString();
      }
      document.cookie =
          cookieKey + "=" + cookieValue + ";" + expiryDate + ";path=/";
  };
  
  function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === " ") {
              c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
          }
      }
      return "";
  }
  
  function RequireAuth() {
      let auth = getCookie("family-handbook_logged-in");
    let location = useLocation();
  
    if (!auth) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      // ...Currently just sending them backt to the home page
      return <Navigate to="/login" state={{ from: location }} />;
    }
  
    return <Outlet />;
  }

  return (
    <BrowserRouter>
    <Routes>
    <Route path="/login" element={<Login setCookie={setCookie} />} />
      <Route element={<RequireAuth />}>
        <Route path="/*" element={<Home />} >
          <Route index element={<MarkdownPage />}  />
          <Route path=":fileName/*" element={<MarkdownPage />}  />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
