import React, { useState, useEffect, useCallback } from "react";

//Initialize media query hook for  use with ad banners
const useMediaQuery = (width) => {
    const [targetReached, setTargetReached] = useState(false);
    
    const updateTarget = useCallback((e) => {
        if (e.matches) {
            setTargetReached(true);
        } else {
            setTargetReached(false);
        }
    }, []);
    
    useEffect(() => {
        const media = window.matchMedia(`(min-width: ${width}px)`);
        media.addListener(updateTarget);
    
        // Check on mount (callback is not called until a change occurs)
        if (media.matches) {
            setTargetReached(true);
        }
    
        return () => media.removeListener(updateTarget);
    }, []);
    
        return targetReached;
};

export default useMediaQuery