import React, { useState, useEffect } from "react";
import Accordion from "../components/accordion"; 
import categories from "../data/menuItems.json";
import Loading from "./loading";

const AccordionList = ( { fileName, navigatedToWildCard } ) => {
    const [subSections, setSubSections] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let currentCategory = categories.filter(category => category.slug === fileName)
        currentCategory = currentCategory.pop() 

        let subContentList = [];

        currentCategory.subItems.map(category => {
            let slug = category.slug;
            const filePath = require(`../data/markdown/${currentCategory.slug}/${category.slug}.md`);

            return fetch(filePath)
            .then(response => {
              return response.text()
            })
            .then(markdown => {
                let subContent = {slug, markdown}
                return subContentList.push(subContent)
            })
        })
        setSubSections(subContentList)
        setTimeout(function() {
            setLoading(false)
          }, 500);
        // setLoading(false)
    }, [fileName]);

    return (
       <>
            {loading && <Loading loading={loading} />}
            
            {!loading && subSections.map((subSection, index) => {
                return (
                    <Accordion 
                        key={index} 
                        subSection={subSection} 
                        navigatedToWildCard={navigatedToWildCard} 
                    />
                )
            })}
       </>
    )
}

export default AccordionList;